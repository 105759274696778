import React, { useEffect, useState } from "react";

interface TypewriterProps {
  text: string;
  speed?: number;
}

const Typewriter: React.FC<TypewriterProps> = ({ text, speed = 100 }) => {
  const [index, setIndex] = useState<number>(0);
  const [currentText, setCurrentText] = useState<string>("");

  useEffect(() => {
    const timer = setTimeout(() => {
      if (index < text.length) {
        setCurrentText(currentText + text.charAt(index));
        setIndex(index + 1);
      }
    }, speed);
    return () => clearTimeout(timer);
  }, [index, text, speed]);

  return <span>{currentText}</span>;
};

export default Typewriter;
