import HeroPage from "../sections/HeroPage";
import SplitImage from "../sections/SplitImage";
import image from "../assets/logo1.png";
import Coworkers from "../sections/Coworkers";
import Headline from "../components/Headline";

export default function About() {
  return (
    <>
      <HeroPage
        title="Om oss"
        undertext="Erfaret VVS-team för pålitliga lösningar"
      />
      <SplitImage
        image={image}
        title="Om oss"
        description="Vår rörmokarhistoria och engagemang i Stockholm"
        alignImage="right"
      >
        <p>
          EHB startades år 2014 och har sedan 2018 ägts och drivits av Oliver,
          Thomas och Svante. Tillsammans med resten av bolaget utgör vi en grupp
          glada vvs-montörer med fokus på snabb återkoppling, smidiga lösningar
          och bred kunskap. Vi utför alla jobb från Stockholms innerstad, till
          öarna i skärgårdens utkanter.
        </p>
      </SplitImage>
      <Headline title={"Teamet"} />

      <Coworkers />
    </>
  );
}
